.background-image {
    position: relative;
    background-color: black;
}

.background-image:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    /* background-image: url(${REACT_APP_BG_IMAGE_ADDRESS}); */
    background-image: url("../src/assets/logo/TpsLogo600x600.png");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

.app {
    position: relative;
    min-height: 100vh;
    width: 100%;
}

body {
    background-color: #1c1c1c;
    color: #fff;
    font-family: Arial, sans-serif;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    /* padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px; */
    border-radius: 5px;
    border: none;
    background-color: #2b2b2b;
    color: #fff;
    font-family: "medium_regular";
}

/* .user-attribute-edit-field {
    min-width: 80%;
} */

.gender-dropdown-selector {
    background-color: rgb(43, 43, 43);
}
.MuiMenu-list {
    background-color: rgb(43, 43, 43);
}

.Mui-Menu-Item {
    color: white !important;
}

.gender-change {
    min-width: 100%;
}

.logo {
    height: 80px;
    width: 80px;
}

.logout {
    font-size: 12px;
}

.login-info-text {
    color: #8a8a8a;
}

.bt-same-size {
    width: 80%;
    padding: 1vh;
    border: 0vh;
    margin: 0vh;
}
.bt-same-size-user-attributes {
    width: 80%;
}

.under-line-link-text {
    list-style: underline;
}

p a {
    text-decoration: none;
    color: white;
}

.linkTxt {
    text-decoration: none;
    color: black;
    font-family: "medium_regular";
}

.css-ahj2mt-MuiTypography-root {
    font-family: "medium_regular";
    color: white;
}
.css-ahj2mt-MuiTypography-root a {
    font-family: "medium_regular";
    color: white;
}

.user-attributes-go-back-btn-text {
    color: grey;
}
.user-attributes-go-back-btn-text:hover {
    color: white;
}

.not-found-logo-row {
    margin-top: 15vh;
}
.delete-confirm {
    margin-top: 20vh;
}
.small-logo img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.css-nk89i7-MuiPickersCalendarHeader-root {
    background-color: black !important;
    border-color: black !important;
    /* border-top: 16px !important; */
    border-top: 34px solid black;
    border-bottom: 34px solid black;
    margin-top: 0 !important;
}

/* userattributes selected gender font-family */
div#mui-component-select-gender {
    font-family: "medium_regular";
}

/* userratrributes gender placehold text font-family */
label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.Mui-Menu-Item.css-6v4vyw-MuiFormLabel-root-MuiInputLabel-root {
    font-family: "medium_regular";
}

/* userratrributes gender selected text font-family */
label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.Mui-Menu-Item.css-1dyzr1-MuiFormLabel-root-MuiInputLabel-root {
    font-family: "medium_regular";
}

/* userratrributes birthdate placehold text font-family */
label#\:r9\:-label {
    font-family: "medium_regular";
}

/* userratrributes birthdate placehold text font-family */
.css-nkti7o-MuiFormControl-root-MuiTextField-root .MuiInputLabel-root {
    font-family: "medium_regular";
}

/* userratrributes name and phonenumber placehold text font-family */
.css-10xoyao-MuiFormControl-root-MuiTextField-root .MuiInputLabel-root {
    font-family: "medium_regular";
}

/* userattributes kalenteri ikoni */
.css-i4bv87-MuiSvgIcon-root {
    color: white;
    background-color: rgb(43, 43, 43) !important;
    padding: 16px, 0px !important;
    border-top: 16.5px rgb(43, 43, 43) !important;
    border-bottom: 16.5px rgb(43, 43, 43) !important;
    margin-right: 8px;
}

/* userattribute birthdate mobileview  */
.MuiPickersLayout-toolbar.css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
    background-color: black;
    color: white;
}

/* select date */
.css-1hbyad5-MuiTypography-root {
    color: white;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 0px !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    /* background-color: rgba(228, 219, 233, 0.25) !important; */
    border-radius: 0% !important;
    margin: 0px !important;
    padding: 0px !important;
}

.css-1laqsz7-MuiInputAdornment-root {
    margin-left: 0px !important;
    border-top: 16.5px rgb(43, 43, 43) !important;
    border-bottom: 16.5px rgb(43, 43, 43) !important;
    /* margin-bottom: 16.5px !important;
    background-color: rgb(43, 43, 43) !important; */
}

.css-nk89i7-MuiPickersCalendarHeader-root,
.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon,
.css-1vooibu-MuiSvgIcon-root {
    color: white !important;
}

.mobile-date-picker-input-field {
    margin-right: 0;
}

.footer {
    font-size: 8px;
    margin-left: 1vh;
    margin-right: 2vh;
}

.under-line-link-text {
    text-decoration-line: underline;
}

@media (max-width: 400px) {
    p {
        font-size: 13px;
    }
}

@media (min-width: 300px) {
    .footer {
        font-size: 10px;
        margin-left: 1vh;
        margin-right: 2vh;
    }
}
@media (min-width: 390px) {
    .footer {
        font-size: 12px;
        margin-left: 1vh;
        margin-right: 1vh;
    }
}

@media (min-width: 576px) {
    .footer {
        font-size: 14px;
        margin-left: 3vh;
        margin-right: 3vh;
    }
}
@media (min-width: 992px) {
    .footer {
        margin-left: 10vh;
        margin-right: 10vh;
    }
}
@media (min-width: 1200px) {
    .footer {
        margin-left: 20vh;
        margin-right: 20vh;
    }
}
@media (min-width: 1500px) {
    .footer {
        margin-left: 25vh;
        margin-right: 25vh;
    }
}

/* font adjustments */
h2,
h1 {
    font-family: "bold_semicond";
}
h3 {
    font-family: "bold_semicond";
}

p {
    font-family: "medium_regular";
}

.btn {
    font-family: "medium_regular";
}

@font-face {
    font-family: "bold_regular";
    src: url("../src/assets/fonts/varsity_type_foundry_redzone_bold_regular.otf")
        format("opentype");
}

@font-face {
    font-family: "bold_semicond";
    src: url("../src/assets/fonts/varsity_type_foundry_redzone_bold_semicond.otf")
        format("opentype");
}

@font-face {
    font-family: "medium_regular";
    src: url("../src/assets/fonts/varsity_type_foundry_redzone_medium_regular.otf")
        format("opentype");
}

/* Loader icon  */
.loader {
    border: 10px solid #575757;
    border-top: 10px solid #363636;
    border-radius: 50%;
    /* width: 80px;
    height: 80px; */
    position: absolute;
    width: 10vh;
    height: 10vh;
    top: 40%;
    left: 40%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
